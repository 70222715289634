import React from "react"

const Logo = () => (
  <div className="site-logo">
    <a href="/">
      <img src="/assets/thunk-hex-blue-logo-small.png" alt="thunk Software"/>
    </a>
  </div>
)

export default Logo