import React from "react"

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>thunk Software is a trading name of Kavand Ltd, Company Registration No. NI671803</p>
      <br/>
      <br/>
      <p><small>Website template by <a href="https://stackrole.com" target="_blank" rel="noreferrer">Stackrole</a></small></p>
    </div>
  </footer>
)

export default Footer